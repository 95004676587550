.auth-wrapper {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: white;
}

h1,
h2 {
  text-align: center;
  /* margin-bottom: 30px !important; */
}

h1 {
  font-size: 2.6rem !important;
}

.form-div {
  width: 100%;
  height: calc(100vh - 135px);
  display: flex;
  overflow-y: auto;
}

.form_div_inner_wrapper {
  margin: auto;
  width: 500px;
  height: auto;
}

.form_div_inner_wrapper .ant-form-item-explain{
  display: flex;
  gap: 10px;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
  font-size: 18px !important;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.ant-input {
  font-style: 18px !important;
}

.ant-form-item:not(:nth-child(3)) {
  margin-bottom: 25px !important;
}

.confirm-password-input-field .ant-input-affix-wrapper {
  border-radius: 8px !important;
}

.confirm-password-input-field .ant-input-affix-wrapper input {
  padding: 0.8rem !important;
}

input {
  font-size: 1rem !important;
  padding: 1rem 0.8rem !important;
  border-radius: 8px !important;
  background-color: hsla(0, 0%, 100%, .1) !important;
}

button {
  width: 100% !important;
  font-size: 18px !important;
  height: auto !important;
  margin-bottom: 10px !important;
  background-color: #3952ff !important;
  padding: 0.8rem !important;
  border-radius: 8px !important;
}

.ant-form-item-control-input-content {
  font-size: 1rem !important;
}

a {
  color: #5e72e4 !important;
  font-size: 1rem !important;
  padding-bottom: 10px !important;

}

.ant-alert {
  font-size: 1rem !important;
  padding: 1em !important;
  border-radius: 8px !important;
  text-align: center !important;
}

.ant-alert-error {
  background-color: bisque !important;
  /* border-color: red !important; */
}

.ant-alert-error .ant-alert-message {
  color: red !important;

}


.footer_wrapper {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  background-color: whitesmoke;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer_wrapper p {
  margin-bottom: 0;
}

.footer_wrapper p a {
  color: black !important;
  font-size: 14px !important;
}

.footer_wrapper p a:hover {
  color: #3952ff !important;
}

.header_div_wrapper {
  padding: 20px;
  display: flex;
  gap: 30px;
  align-items: center;
  height: 85px;
}

.header_div_wrapper h2{
  margin: 0;
}

.ant-select-selector{
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 8px !important;
  font-size: 1rem !important;
}